import React, { useEffect, useRef, useState } from 'react'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container } from 'react-bootstrap';
import Cursor from './Cursor';
import Pagination from './pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const BrowseArticles = ({ location, articles }) => {
  const locationRef = useRef({ location: null })

  useEffect(() => {
    AOS.init({
      offset: -10,
      delay: 50,
      duration: 800,
      easing: 'ease',
      mirror: true
    });
  }, [])

  // if location url change set current page to 0.
  useEffect(() => {
    if (!locationRef.current.location) {
      locationRef.current.location = location
    } else if (locationRef.current.location !== location) {
      setCurrentPage(0)
      locationRef.current.location = location
    }
  }, [location])

  const ARTICLES_PER_PAGE = 5
  const [currentPage, setCurrentPage] = useState(0)
  const paginationSliceFrom = currentPage * ARTICLES_PER_PAGE
  const paginationSliceTo = paginationSliceFrom + ARTICLES_PER_PAGE

  return (
    <Container fluid className='NewsAndPressHome'>
      <div className='d-flex flex-row justify-content-between mt-4 mb-4'>
        <h3>NEWS & PRESS.</h3>
        <h3 className='allArticlesLink'><Link to="/articles">ALL<Cursor /></Link></h3>
      </div>
      <div className='d-flex flex-column'>
        {
          articles
            .slice(paginationSliceFrom, paginationSliceTo)
            .map((article, i) => {
              return (
                <Link to={`/articles/${article.uid}`} key={i} className="article-link pt-2" data-aos="fade-up">
                  <div className="d-none d-lg-block">
                    <Cursor light />
                  </div>
                  <div className='article-card d-flex flex-column flex-lg-row align-items-lg-center py-3'>
                    <div className="d-flex  flex-row flex-lg-column justify-content-start articleDetails">
                      <h3 className='article-card-date m-0'>{article.data.date}</h3>
                      {/* <h4 className='text-wrap'>{article.data.category}</h4> */}
                    </div>
                    <h4 className='pe-lg-5 py-3 articleTitle'>{article.data.title}</h4>
                    <div className='articleArrowWrapper' >
                      <div className='rightArrowLinkWhite d-none d-lg-block'>
                        <StaticImage
                          src='../images/shapes/white right arrow.svg'
                          alt="visit article arrow"
                        />
                      </div>
                      <div className='rightArrowLink'>

                        <StaticImage
                          src='../images/shapes/Group 2.svg'
                          alt="visit article arrow"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })
        }
      </div>
      {articles.length > ARTICLES_PER_PAGE &&
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={articles.length} itemsPerPage={ARTICLES_PER_PAGE} />
      }
    </Container>
  )
}

export default BrowseArticles;